<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <TopBar
            v-model:selectAll="selectAll"
            v-model:line-options="lineOptions"
            v-model:rows="rows"
            v-model:items="items"
            v-model:selectedLineOption="selectedLineOption"
            :path="'warehouses::' + this.type + '::*'"
            :show-languages="false"
            :show-currencies="false"
            v-model:selectedItems="selectedItems"
            :methods="methods"
          />
          <DynamicTable
            v-model:rows="rows"
            :items="filteredItems"
            v-model:select-all="selectAll"
            v-on:order="orderBy"
            :order="order"
            :show-pin="false"
            :select-sub="false"
            check-delete-row="quantity"
            type="products"
            :fix-height="true"
            v-on:selected="(e) => (selectedItems = e)"
            v-on:update:val="valUpdated"
            v-on:loadMore="loadMore"
            :load-in-process="loadInProcess"
            :key="tableKey"
          />
        </div>
      </div>
    </div>
    <NewManualIncoming v-model:show="showManualIncoming" ref="manualIncoming" />
    <ConfirmModal
      ref="noWarehouseModal"
      :title="$t(`manufacturingMaterials.noWarehouseSelected3`)"
      text="please select a warehouse before adding a new income!"
      :show-ok="false"
      cancel-button="Ok"
    />
  </div>
</template>

<script>
import http from "../../modules/http";
import lineOptions from "../../modules/lineOptions";
import TopBar from "../../components/lists/TopBar";
import DynamicTable from "../../components/lists/DynamicTable";
import store from "@/store";
import NewManualIncoming from "../../components/modals/NewManualIncoming.vue";
import ConfirmModal from "../../components/modals/ConfirmModal.vue";
import { useStore } from "vuex";
import ws from "../../modules/ws";

export default {
  name: "ManufacturingMaterials",
  components: {
    ConfirmModal,
    NewManualIncoming,
    DynamicTable,
    TopBar,
  },
  data() {
    return {
      store: useStore(),
      rows: [],
      methods: [
        {
          title: "Create income",
          method: this.createIncome,
          always: true,
        },
      ],
      selectedLineOption: 1,
      lineOptions: [],
      selectAll: false,
      selectedItems: [],
      items: [],
      filterOptions: [],
      orderItems: {},
      categories: [],
      showManualIncoming: false,
      tableKey: 1,
      filtersTimeOut: null,
      page: 1,
      canLoadMore: true,
      loadId: undefined,
      loadInProcess: false,
      order: {
        row: null,
        desc: true,
      },
    };
  },
  watch: {
    filteredItems() {
      this.tableKey++;
    },
    filters: {
      deep: true,
      handler() {
        if (this.loadInProcess) {
          return;
        }
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.page = 1;
          this.loadData();
        }, 450);
      },
    },
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    pricePartners() {
      return this.store.state.pricePartners;
    },
    q() {
      return store.state.topBar.q;
    },
    filterPage() {
      return this.store.state.filterPage;
    },
    filters() {
      if (
        Object.keys(this.store.state.filters).indexOf("production_materials") <
        0
      ) {
        return {};
      }
      return this.store.state.filters?.production_materials;
    },
    filteredItems() {
      if (this.q === null || this.q.length < 1) {
        return this.items;
      }
      let q = this.q.toLowerCase();
      return this.items.filter(
        (e) =>
          e.name.toLowerCase().indexOf(q) > -1 ||
          e.serial.toLowerCase().indexOf(q) > -1 ||
          e.sku.toLowerCase().indexOf(q) > -1
      );
    },
  },
  mounted() {
    this.loadData();
    this.editable = false;

    ws.init();

    ws.subscribe("modify", "manufacturingMaterial", () => {
      this.page = 1;
      this.loadData();
    });

    window.apps.subscribe("setValue:manualIncoming", (id, amount) => {
      console.log(id);
      console.log(amount);
      amount = Number.parseInt(amount);
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.variant_id === id) {
            this.orderItems[item.variant_id] = item;
            continue;
          }
          item.create_income = amount;
          this.orderItems[item.variant_id] = item;
        }
      } else {
        this.orderItems[id] = this.items.find((e) => e.variant_id === id);
      }
    });

    window.apps.subscribe("changeValue:manualIncoming", (id, amount) => {
      console.log(id);
      console.log(amount);
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.variant_id === id) {
            this.orderItems[item.variant_id] = item;
            continue;
          }
          if (item.create_income === undefined || item.create_income === null) {
            item.create_income = amount > 0 ? amount : 0;
          } else {
            if (item.create_income + amount > -1) {
              item.create_income += amount;
            }
          }
          this.orderItems[item.variant_id] = item;
        }
      } else {
        this.orderItems[id] = this.items.find((e) => e.variant_id === id);
      }
    });
  },
  methods: {
    createIncome() {
      if (this.warehouse === null) {
        this.$refs.noWarehouseModal.showModal();
      } else {
        this.showManualIncoming = true;
        //this.$refs.manualIncoming.setCharacteristics(this.characteristics);
        this.$refs.manualIncoming.setEmpty();
        for (const key in this.orderItems) {
          if (this.orderItems[key].create_income < 1) {
            continue;
          }
          this.$refs.manualIncoming.addProductVariant(
            this.orderItems[key],
            this.orderItems[key].create_income
          );
        }
      }
    },
    setFilters() {
      this.filterOptions = [];

      this.filterOptions.push({
        name: "Partners",
        type: "string",
        key: "partners",
        valueName: "name",
        value: {},
        values: this.pricePartners,
      });

      this.filterOptions.push({
        name: "Categories",
        type: "string",
        key: "category",
        valueName: "name",
        value: {},
        values: this.categories,
      });

      this.$store.commit("setFilterPage", "production_materials");
      this.$store.commit("setFilterOptions", this.filterOptions);
      this.$store.commit("setFilterOptionsStock", false);
      this.$store.commit("setFilterOptionsPrice", false);
    },
    orderBy(order) {
      this.items = undefined;
      this.order = order;
      this.page = 1;
      this.loadData();
    },
    setFilterUrl(url) {
      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      return url;
    },
    loadMore() {
      if (this.canLoadMore) {
        this.canLoadMore = false;
        this.loadInProcess = true;
        this.page++;
        this.loadData();
      } else {
        this.loadInProcess = false;
      }
    },
    loadData() {
      const loadId = (this.loadId = Math.random());
      if (this.page == 1) {
        this.items = [];
        this.tableKey++;
      }
      http
        .fetch(
          this.setFilterUrl(
            `/production/materials?page=${this.page}&order[desc]=${
              this.order.desc ? 1 : 0
            }&order[row]=${this.order.row ? this.order.row : ""}`
          )
        )
        .then((data) => {
          if (loadId !== this.loadId) {
            return;
          }

          if (this.page === 1) {
            this.rows = lineOptions.init(data.rows, data.line_options);
            this.operationType = data.data.operation_type;
            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0].id;
          }

          for (const product of data.data) {
            if (
              this.categories.findIndex((e) => e.id === product.category_id) ===
              -1
            ) {
              this.categories.push({
                id: product.category_id,
                name: product.category,
              });
            }
            product.image =
              product.image !== null
                ? product.image.indexOf("http") === 0
                  ? product.image
                  : this.mediaServer + "/" + product.image
                : null;

            if (product?.variants !== undefined) {
              for (const variant of product.variants) {
                variant.image =
                  variant.image !== null
                    ? this.mediaServer + "/" + variant.image
                    : null;
              }
            }
          }

          if (this.page == 1) {
            if (this.pricePartners?.length < 1) {
              http.fetch("/crm/partners/has-income").then((data) => {
                this.$store.commit("setPricePartners", data);
                if (this.filterPage !== "production_materials") {
                  this.setFilters();
                }
              });
            } else if (this.filterPage !== "production_materials") {
              this.setFilters();
            }

            this.items = data.data;
          } else {
            for (const row of data.data) {
              this.items.push(row);
            }
          }
          this.canLoadMore = data.data.length > 0;
          this.loadInProcess = false;
          this.tableKey++;
        });
    },
  },
};
</script>
